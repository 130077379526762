/**
 * iId === idIssuer
 * ivId === investorsId
 * owoId === omnibusWalletOwnerId
 * from === key of route
 */
function getFromSearchQuery(from, search) {
  const fromQuery = from ? `?from=${encodeURIComponent(from)}` : '';
  const searchQuery = from && search ? `&search=${encodeURIComponent(search)}` : '';
  return `${fromQuery}${searchQuery}`;
}

export default {
  index: '/',
  systemCountries: () => '/system-config/countries',
  systemAgreements: () => '/system-config/agreements',
  systemQuestions: () => '/system-config/questions',
  systemEmailTemplates: () => '/system-config/email-templates',
  systemTexts: () => '/system-config/system-texts',
  clientConfiguration: () => '/system-config/client-configuration',
  signup: () => '/signup',
  twoFactorAuth: () => '/two-factor-auth',
  createIssuer: () => '/create-issuer',
  issuerList: () => '/issuer-list',
  messagesInbox: id => `/${id}/messages/inbox`,
  messagesCompose: id => `/${id}/messages/compose`,
  messagesSent: id => `/${id}/messages/sent`,
  operators: () => '/operators',
  messagesItem: (iId, id) => `/${iId}/messages/${id}`,
  dashboard: (id, tokenId) => `/${id}/${tokenId}/dashboard`,
  holders: (id, tokenId, query) => `/${id}/${tokenId}/investors/holders${
      query ? `?search=${encodeURIComponent(query)}` : ''
    }`,
  outreach: (id, tokenId) => `/${id}/${tokenId}/investors/outreach`,
  issueDetails: (id, tokenId) => `/${id}/${tokenId}/investors/issue-details`,
  signatures: (id, tokenId, status = 'pending') => `/${id}/${tokenId}/signatures?status=${status}`,
  distributions: (id, tokenId) => `/${id}/${tokenId}/distributions`,
  distributionSnapshots: (idIssuer, tokenId, idSnapshot, name) => `/${idIssuer}/${tokenId}/distributions/${idSnapshot}/${name}`,
  manageToken: (id, tokenId) => `/${id}/${tokenId}/configuration/fundraise-token/manage-token`,
  fundraiseConfiguration: (id, tokenId) => `/${id}/${tokenId}/configuration/fundraise`,
  assetContent: (id, tokenId) => `/${id}/${tokenId}/configuration/opportunity/summary`,
  tokenConfiguration: (id, tokenId) => `/${id}/${tokenId}/configuration/fundraise-token`,
  jurisdictionsPerTokenConfiguration: (id, tokenId) => `/${id}/${tokenId}/configuration/jurisdictions`,
  transferControlBook: (id, tokenId) => `/${id}/${tokenId}/transfer-agent/control-book`,
  fundraiseContent: id => `/${id}/issuer-configuration/fundraise-content`,
  transferMSF: (id, tokenId) => `/${id}/${tokenId}/transfer-agent/msf`,
  transferDTL: (id, tokenId) => `/${id}/${tokenId}/transfer-agent/dtl`,
  affiliateManagement: (id, tokenId) => `/${id}/${tokenId}/transfer-agent/affiliate-management`,
  transferProcedure: (id, tokenId) => `/${id}/${tokenId}/transfer-agent/procedure`,
  snapshots: (idIssuer, tokenId) => `/${idIssuer}/${tokenId}/snapshots`,
  snapshotDetails: (idIssuer, tokenId, idSnapshot, name) => `/${idIssuer}/${tokenId}/snapshots/${idSnapshot}/${name}`,
  onboarding: (id, tokenId, query) => `/${id}/investors/onboarding${
      query ? `?search=${encodeURIComponent(query)}` : ''
    }`,
  // tokenId used for compatibility with holders and fundraise routes
  searchonboarding: (id, search) => `/${id}/investors/onboarding?search=${encodeURIComponent(search)}`,
  generalConfiguration: id => `/${id}/issuer-configuration/general`,
  jurisdictionsConfiguration: id => `/${id}/issuer-configuration/jurisdictions`,
  opportunitiesConfiguration: id => `/${id}/issuer-configuration/opportunities`,
  issuerAffiliates: id => `/${id}/advanced/issuer-affiliates`,
  issuerAgreements: id => `/${id}/advanced/issuer-agreements`,
  issuerBank: id => `/${id}/advanced/issuer-bank-accounts`,
  issuerConfiguration: id => `/${id}/advanced/issuer-configuration-variables`,
  issuerCurrencies: id => `/${id}/advanced/issuer-currencies`,
  issuerEmailTemplates: id => `/${id}/advanced/issuer-email-templates`,
  issuerPlatformDocuments: id => `/${id}/advanced/issuer-platform-documents`,
  issuerQuestions: id => `/${id}/advanced/issuer-questions`,
  issuerTexts: id => `/${id}/advanced/issuer-texts`,
  issuerPrerenderedAddresses: id => `/${id}/advanced/issuer-prerendered-addresses`,
  privacyControl: id => `/${id}/privacy-control`,
  edit: {
    operators: (iId, id) => `/${iId}/operators/${id}/edit-entity`,
    investors: (iId, id) => `/${iId}/investors/${id}/edit-entity`,
    details: (iId, id) => `/${iId}/investors/${id}/edit-entity`,
  },
  create: {
    operators: iId => `/${iId}/operators/create`,
  },
  viewDetails: (iId, tokenId, id, from, search) => `/${iId}/${tokenId}/investors/details/${id}${getFromSearchQuery(
      from,
      search,
    )}`,
  viewLegalSigner: (iId, tokenId, userId, legalSignerId) => `/${iId}/${tokenId}/investors/details/${userId}/legal-signer/${legalSignerId}`,
  viewOmnibusDetails: (iId, tokenId, owoId, from, search) => `/${iId}/${tokenId}/omnibus-wallet-owners/details/${owoId}${getFromSearchQuery(
      from,
      search,
    )}`,
  securitizeId: () => '/securitize-id',
  securitizeIdMfe: () => '/securitize-id/mfe/investors',
  securitizeIdInvestor: id => `/securitize-id/${id}`,
  securitizeIdInvestorMfe: id => `/securitize-id/${id}`,
  securitizeIdMarketsInvestor: id => `/markets/investors/${id}`,
  securitizeIdTranslations: () => '/securitize-id/translations',
  securitizeIdTranslationsMfe: () => '/securitize-id/mfe/translations',
  securitizeIdLegalSigner: ({ investorId, legalSignerId }) => `/securitize-id/${investorId}/legal-signer/${legalSignerId}`,
  securitizeIdLegalSignerMfe: ({ investorId, legalSignerId }) => `/securitize-id/mfe/${investorId}/legal-signer/${legalSignerId}`,
  securitizeIdWallets: () => '/securitize-id/wallets',
  securitizeIdWalletsMfe: () => '/securitize-id/mfe/wallets',
  securitizeIdPromotionsMfe: () => '/securitize-id/mfe/promotions',
  opportunities: {
    create: (id, tokenId) => `/${id}/${tokenId}/configuration/opportunities/add`,
  },
  brokerDealerOverview: () => '/markets/overview',
  brokerDealerInvestors: () => '/markets/investors',
  brokerDealerInvestorDetails: ({ investorId }) => `/markets/investors/${investorId}`,
  brokerDealerLegalSigner: ({ investorId, legalSignerId }) => `/markets/investors/${investorId}/legal-signer/${legalSignerId}`,
  brokerDealerOpportunities: () => '/markets/opportunities',
  brokerDealerEditOpportunity: ({ opportunityId }) => `/markets/opportunities/${opportunityId}`,
  brokerDealerSystemConfig: () => '/markets/config',
  brokerDealerAccreditations: () => '/markets/accreditations',
  auditLog: () => '/audit-log',
  auditLogIssuer: id => `/${id}/audit-log`,
  fundraise: (id, tokenId) => `/${id}/${tokenId}/investors/fundraise`,
  fundraiseOld: (id, tokenId, query) => `/${id}/${tokenId}/investors/fundraise-old${
    query ? `?search=${encodeURIComponent(query)}` : ''
  }`,
  transferAgent: (id, tokenId) => `/${id}/${tokenId}/transfer-agent`,
  transferAgentControlBook: (id, tokenId) => `/${id}/${tokenId}/transfer-agent/control-book`,
  transferAgentMSF: (id, tokenId) => `/${id}/${tokenId}/transfer-agent/msf`,
  transferAgentDTL: (id, tokenId) => `/${id}/${tokenId}/transfer-agent/dtl`,
  transferAgentProcedure: (id, tokenId) => `/${id}/${tokenId}/transfer-agent/procedure`,
  transferAgentAffiliateManagement: (id, tokenId) => `/${id}/${tokenId}/transfer-agent/affiliate-management`,
};
