export default {
  manageToken: {
    steps: {
      tokenContract: {
        title: 'Deploy Token Contract',
        description: 'Set basic token contract configuration',
      },
      compliance: {
        title: 'Compliance Rules',
        description: 'Set and view previous token compliance rules',
      },
      manageRoles: {
        title: 'Manage Roles',
        description: 'Set roles & wallet syncer for the token',
      },
      activateToken: {
        title: 'Activate Token',
        description: 'Deploy the token using the configuration on each step',
      },
    },
    status: {
      pending: 'PENDING',
      ready: 'READY',
      done: 'DONE',
    },
    setTokenContract: {
      setTokenContract: 'Set Token Contract',
      tokenName: 'Token Name',
      appleExample: 'e.g. Apple',
      tokenAddress: 'Token Address',
      pendingTokenDeployment: 'Pending Token Deployment',
      tokenTicker: 'Token Ticker',
      appleTickerExample: 'e.g. APPL',
      tokenDecimals: 'Token Decimals',
      tokenBlockchain: 'Blockchain',
      zero: '0',
      tokenUrlExample: 'e.g. https://tokenUrl.com',
      tokenUrl: 'Token URL',
      tokenIconUriExample: 'e.g. https://tokenUrl.com/example.png',
      tokenIconUri: 'Icon URI',
      tokenMetadataHash: 'Token Metadata Hash',
      tokenTotalSupply: 'Token Total Supply',
      totalSupplyExample: '100000',
    },
    swapContract: {
      web3FlowConfig: 'Web3 Flow Config',
      custodianAddress: 'Custodian Address',
      stableCoin: 'Stable Coin',
      swapContractAddress: 'Swap Contract Address',
      swapContractPending: 'Pending Swap Contract Deployment',
    },
    complianceRules: {
      compliance: 'Compliance',
      tabs: {
        complianceConfiguration: 'Compliance Configuration',
        complianceHistory: 'Compliance History',
        complianceCounters: 'Compliance Counters',
      },
      headers: {
        variable: 'Variable',
        value: 'Value',
        comment: 'Comment',
      },
      variables: {
        totalInvestorsLimit: 'Total Investors Limit',
        minUSTokens: 'Min US Tokens',
        minEUTokens: 'Min EU Tokens',
        usInvestorsLimit: 'US Investors Limit',
        usAccreditedInvestorsLimit: 'US Accredited Investors Limit',
        nonAccreditedInvestorsLimit: 'Non Accredited Investors Limit',
        maxUSInvestorsPercentage: 'Max US Investors Percentage',
        blockFlowbackEndTime: 'Block Flowback End Time',
        nonUSLockPeriod: 'Non US Lock Period',
        minimumTotalInvestors: 'Minimum Total Investors',
        minimumHoldingsPerInvestor: 'Minimum Holdings Per Investor',
        maximumHoldingsPerInvestor: 'Maximum Holdings Per Investor',
        euRetailInvestorsLimit: 'EU Retail Investors Limit',
        usLockPeriod: 'US Lock Period',
        jpInvestorsLimit: 'JP Investors Limit',
        forceFullTransfer: 'US Force Full Transfer',
        worldWideForceFullTransfer: 'World Wide Force Full Transfer',
        forceAccredited: 'Force Accredited',
        forceAccreditedUS: 'Force Accredited US',
        euCountries: 'EU Countries',
        forbiddenCountries: 'Forbidden Countries',
        countriesCompliance: 'Countries Compliance',
        authorizedSecurities: 'Number of authorized securities',
        disallowBackDating: 'Disallow Back Dating',
      },
      warning:
        'There is a pending transaction. These compliance rules have not yet been applied on chain.',
    },
    complianceHistory: {
      historyTableFields: {
        date: 'Date',
        blockNumber: 'Block Number',
        status: 'Status',
        view: 'View',
      },
      complianceRulesHistoryFields: {
        variable: 'Variable',
        value: 'Value',
        comment: 'Comment',
      },
      countryComplianceHistoryFields: {
        countryCode: 'Country Code',
        complianceStatus: 'Compliance Status',
        comment: 'Comment',
      },
    },
    complianceCounters: {
      counterTableFields: {
        complianceCounter: 'Compliance Counter',
        countryCode: 'Country Code',
        count: 'Value',
        notAvailableComplianceCounters: 'Compliance counters are not available',
        notDeployedToken:
          'Compliance counters will be available when token is deployed to the blockchain',
      },
      buttons: {
        refresh: 'Refresh',
      },
    },
    manageRoles: {
      manageRoles: 'Manage Roles',
      multisig: 'Multi-Signature Wallets',
      master: 'Master',
      omnibusTBE: 'Omnibus TBE Wallet',
      redemptionAddress: 'Redemption Wallet',
      walletMaster: 'Wallet Master',
      walletSyncer: 'Wallet Syncer',
      walletAddress: 'Wallet Address',
      privateKey: 'Private Key',
      walletBalance: 'Wallet Balance',
      addRole: 'Add Role',
      headers: {
        role: 'Role',
        walletAddress: 'Wallet Address',
        comment: 'Comment',
        type: 'Type',
      },
      roles: {
        issuer: 'ISSUER',
        exchange: 'EXCHANGE',
        transfer_agent: 'TRANSFER-AGENT',
      },
      ownership: {
        single: 'Single',
        multisig: 'MultiSig Wallet',
        hsm: 'HSM Signer',
      },
      useHSM: 'Use HSM Wallet',
      HSMinfo_1: 'By default, HSM is used as the syncer wallet, so you do not need to configure anything.',
      HSMinfo_2: 'However, you can configure your own wallet by clicking on the switch.',
      HSMWallet: 'HSM Wallet',
      privateWallet: 'Private Wallet',
    },
    activateToken: {
      modal: {
        activate: 'Activate',
        tokenDeployment: 'Token Deployment and Activation',
        tokenActivation: 'Token Activation',
        detailsIntro:
          'By clicking on Activate you will start the token deployment process with the following details:',
        detailsNote:
          'This wallet will deploy the token, and must have enough balance. Be mindful of future users and send more funds to the wallet. This action is non-reversible and will have an operational cost of Blockchain network activity.',
        tokenName: 'Name:',
        tokenTicker: 'Ticker:',
        tokenDecimals: 'Decimals:',
        gasPrice: 'Gas Price: ',
        gWei: 'GWEI',
        eth: 'ETH',
        estimatedDeploymentCost: 'Estimated deployment cost: ',
        gasPriceNote:
          'NOTE: putting a lower gas price may delay the deployment and have the process fail, which will spend at least part of the estimated amount and will require additional gas to complete later.',
        complianceType: 'Compliance:',
        tokenBlockchain: 'Blockchain:',
        continue: 'Continue',
      },
      intro:
        'In this step, we will use the provided token’s configuration collected on previous steps to deploy the token '
        + 'into the Ethereum blockchain network. After a successful token deployment, you will have the option to issue tokens and whitelist Ethereum wallets to receive them',
      introAlgorand:
          'In this step, we will use the provided token’s configuration collected on previous steps to generate an '
          + 'Algorand transaction that will enable the asset. After the transaction is signed and successfully executed, you will have '
          + 'the option to issue tokens and whitelist Algorand wallets to receive them.',
      deploymentLogs: {
        tokenStatus: 'Token Deployment Status: ',
        collapseLogs: 'Collapse All',
        displayLogs: 'Display All',
        deploymentSuccessful: 'Token successfully deployed',
        deploymentFailed: 'The token could not be deployed',
        totalTime: 'Total time:',
        deploymentPending: 'The token is being deployed, please wait...',
        txHash: 'Tx Hash: ',
        initialDate: 'Initial date: ',
        finalDate: 'Final date: ',
        step: 'Step ',
      },
      deployer: {
        deployerWallet: 'Deployer Wallet',
        deployerWalletWarning: 'This wallet will be in charge of deploying the Token, and MUST have enough balance.',
        balance: 'Balance',
        available: 'Available:',
        minimum: 'Minimum:',
        testnetAlert1: 'Please, be mindful of future users and send more funds to the wallet:',
        testnetAlert2: 'You can use this faucet',
        testnetAlert3: 'to add funds to the wallet',
        minimumGasError: 'An error occurred while obtaining minimum deployment gas needed:',
        lite: {
          deployerWallet: 'Deployment wallet: ',
          available: 'Available balance:',
          minimum: 'Minimum balance:',
        },
        walletBalanceError: 'An error occurred while retrieving deployer wallet balance:',
      },
      processingRequest1: 'Processing request, please wait.',
      processingRequest2: 'This could take up some minutes...',
    },
    step: 'STEP',
    continue: 'Continue',
    activate: 'Activate',
    retry: 'Retry',
    deploy: 'Deploy',
    backToToken: 'Go back to Token',
    confirm: 'Are you sure you want to save the changes?',
  },
};
