export default {
  configurationFundraiseToken: {
    title: 'トークン',
    // general section
    general: {
      title: '一般',
      input: {
        fundraise: 'Activate Fundraise / Publish Opportunity', // TODO: translate to japanese
        tokenLifecycleManagement: 'トークンライフサイクル管理',
      },
    },
    addTokenButtonText: 'トークンを追加',
    addTokenModal: {
      title: 'トークン追加',
      newTokenHeader: '新しいトークン',
      modalMessage: 'トークンは一度追加されると削除できませんのでご注意ください',
      input: {
        name: 'トークン名称',
      },
    },
    generalConfirmModal: {
      message: {
        confirm: '変更内容を保存します。よろしいですか？',
      },
    },
    // token details section
    tokenDetails: {
      title: 'トークン詳細',
      label: {
        tokenIcon: 'トークンアイコン',
        recommendedSize: '推奨サイズ:',
        recommendedSizeValue: '500px * 500px',
        recommendedFormat: '推奨フォーマット:',
        recommendedFormatValue: '透過PNG',
        tokenName: 'トークン名',
        defaultTokenValue: 'デフォルトトークン価格',
        isin: 'ISIN', // TODO: translate to japanese
        saft: 'Child (SAFT) Token', // TODO: translate to japanese
        tokenRepresentsDebt: 'トークンを債券として扱う',
        allowDepositWithdraw: 'Allow Deposit / Withdraw', // TODO: translate to japanese
        investorPays: 'Investor Pays', // TODO: translate to japanese,
        evergreenRound: 'Evergreen Round', // TODO: translate to japanese
        decimal: '小数部桁数',
        blockchain: 'ブロックチェーン',
        documents: 'ドキュメント',
        isDripActive: 'Dividend Reinvestment Program', // TODO: translate to japanese
        isScheduledIssuanceEnabled: 'Scheduled Issuance Enabled', // TODO: translate to japaneses
        isWalletScreeningEnabled: 'Wallet AML Screening Enabled', // TODO: translate to japanese
        scheduledIssuanceScheduleTime: 'Issuance schedule: {0} (EST).\n Cut Off Snapshot schedule: {1} (EST).\n If you wish to change this configuration, please contact support.', // TODO: translate to japanese
        isAssetPerformanceActive: 'Enable Asset Performance', // TODO: translate to japanese
        table: {
          type: 'タイプ',
          category: '公開範囲',
          name: '名前',
          title: 'タイトル',
        },
      },
      saftInputDisplayChild: 'This token is a child (SAFT) under {0} token', // TODO: translate to japanese
      saftEmptyDdl: 'There isn’t any available tokens to connect', // TODO: translate to japanese
      error: {
        tokenRepresentsDebt: 'Set token value to represent debt', // TODO: translate to japanese
        isin: 'Set a correct format of International Securities ID. Example: US0378331005 or AU0000XVGZA3', // TODO: translate to japanese
      },
      status: {
        category: {
          basic: '制限なし',
          investorOnly: '投資家のみ',
        },
      },
      button: {
        document: {
          download: 'ダウンロード',
          add: 'ドキュメントの追加',
        },
        manageToken: 'トークンを管理',
      },
      message: {
        tokenNameValidation: 'トークン名を入力してください',
      },
      tokenStatus: 'トークンステータス',
      tokenDeploymentStatus: {
        initial: 'なし',
        pending: '保留中',
        inProgress: '進行中',
        success: 'デプロイ済み',
        failure: '失敗',
      },
      confirmModal: {
        title: '小数部桁数の変更',
        attention: '注意 - ',
        message: '小数部桁数を変更すると、現在発行されているボーナストークン、トレジャリー、発行予定トークン、およびコントロールブックの値に直接影響を与えます。 \n'
          + '桁数を {0} から {1} に変更してもよろしいですか？',
      },
      confirmModalEverGreen: {
        title: 'Evergreen Round', // TODO: translate to japanese
        attention: 'Attention - ', // TODO: translate to japanese
        message: 'You had enabled an evergreen round, please set round details in Fundraise configuration page', // TODO: translate to japanese
        okBtnText: 'Go to fundraise', // TODO: translate to japanese
      },
      historicalTokenValues: {
        title: 'Token Historical Values', // TODO: translate to japanese
        table: {
          tokenValueDate: 'Token Value Date', // TODO: translate to japanese
          modificationDate: 'Modification Date', // TODO: translate to japanese
          tokenValue: 'Token Value', // TODO: translate to japanese
          addedBy: 'Added By', // TODO: translate to japanese
          actions: '',
        },
        actions: {
          addTokenValue: 'Add Token Value', // TODO: translate to japanese
        },
        modal: {
          create: {
            title: 'Add Token Value', // TODO: translate to japanese
          },
          edit: {
            title: 'Edit Token Value', // TODO: translate to japanese
          },
          overwrite: {
            title: 'Token Value Exists', // TODO: translate to japanese
            message: 'For the {0} we already have a value of {1}. Do you want to overwrite it with {2}?', // TODO: translate to japanese
            confirm: 'Overwrite', // TODO: translate to japanese
          },
          fields: {
            tokenValue: 'Token Value', // TODO: translate to japanese
            tokenValueDate: 'Token Value Date', // TODO: translate to japanese
          },
        },
      },
    },
  },
};
