import { SID } from '@securitize/domain';

export const documents = {
  categories: {
    entityDocumentTypes: [
      { value: { docCategory: 'entity', docType: 'adverse-media' }, text: 'Adverse Media Record' },
      { value: { docCategory: 'entity', docType: 'articles-of-corporation' }, text: 'Articles of Corporation' },
      { value: { docCategory: 'entity', docType: 'articles-of-incorporation' }, text: 'Articles of Incorporation' },
      { value: { docCategory: 'entity', docType: 'articles-of-organization' }, text: 'Articles of Organization' },
      { value: { docCategory: 'entity', docType: 'audited-financials' }, text: 'Audited Financials' },
      { value: { docCategory: 'entity', docType: 'authorized-signers-list' }, text: 'Authorized Signers List' },
      { value: { docCategory: 'entity', docType: 'bank-statement' }, text: 'Bank Statement' },
      { value: { docCategory: 'entity', docType: 'board-resolutions' }, text: 'Board of Resolutions' },
      { value: { docCategory: 'entity', docType: 'by-laws' }, text: 'Executed Bylaws' },
      { value: { docCategory: 'entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
      { value: { docCategory: 'entity', docType: 'certificate-of-registration' }, text: 'Certificate of Registration' },
      { value: { docCategory: 'entity', docType: 'certificate-of-good-standing' }, text: 'Certificate of Good Standing' },
      { value: { docCategory: 'entity', docType: 'certificate-of-incorporation' }, text: 'Certificate of Incorporation' },
      { value: { docCategory: 'entity', docType: 'constitutional-document' }, text: 'Constitutional Document' },
      { value: { docCategory: 'entity', docType: 'employment-letter' }, text: 'Employment Letter' },
      { value: { docCategory: 'entity', docType: 'evidence-of-ein' }, text: 'Evidence of EIN' },
      { value: { docCategory: 'entity', docType: 'financial-documents' }, text: 'Financial Documents' },
      { value: { docCategory: 'entity', docType: 'foundation-charter' }, text: 'Foundation Charter' },
      { value: { docCategory: 'entity', docType: 'identity-doc' }, text: 'Identity Document' },
      { value: { docCategory: 'entity', docType: 'idology-report' }, text: 'IDology Report' },
      { value: { docCategory: 'entity', docType: 'list-of-officers-and-directors' }, text: 'List of Officers and Directors' },
      { value: { docCategory: 'entity', docType: 'list-of-trustees' }, text: 'List of Trustees' },
      { value: { docCategory: 'entity', docType: 'lp-list-and-authorised-signers-list' }, text: 'Limited Partner List' },
      { value: { docCategory: 'entity', docType: 'list-of-managers' }, text: 'List of Managers' },
      { value: { docCategory: 'entity', docType: 'members-and-authorised-signers-list' }, text: 'List of Members' },
      { value: { docCategory: 'entity', docType: 'non-profit-bylaws' }, text: 'Non-Profit Bylaws' },
      { value: { docCategory: 'entity', docType: 'offering-agreement' }, text: 'Offering Memorandum' },
      { value: { docCategory: 'entity', docType: 'operating-agreement' }, text: 'Operating Agreement' },
      { value: { docCategory: 'entity', docType: 'operational-address' }, text: 'Operational Address' },
      { value: { docCategory: 'entity', docType: 'org-chart' }, text: 'Organizational Chart' },
      { value: { docCategory: 'entity', docType: 'private-placement-memorandum' }, text: 'Private Placement Memorandum' },
      { value: { docCategory: 'entity', docType: 'partnership-agreement' }, text: 'Partnership Agreement' },
      { value: { docCategory: 'entity', docType: 'proof-of-address' }, text: 'Proof of Address' },
      { value: { docCategory: 'entity', docType: 'selfie' }, text: 'Selfie' },
      { value: { docCategory: 'entity', docType: 'shareholders-list-and-authorised-signers-list' }, text: 'Shareholders & Authorized Signers List' },
      { value: { docCategory: 'entity', docType: 'shareholders-list' }, text: 'Shareholders List' },
      { value: { docCategory: 'entity', docType: 'shareholders-list-25-percent-ubo-control' }, text: 'Shareholders List (25%+ UBO Control)' },
      { value: { docCategory: 'entity', docType: 'tax-document' }, text: 'Tax Document' },
      { value: { docCategory: 'entity', docType: 'document-or-certificate-of-trust' }, text: 'Certificate of Trust' },
      { value: { docCategory: 'entity', docType: 'other' }, text: 'Other' },
    ],
    entityConfidentialDocumentTypes: [
      { value: { docCategory: 'entity', docType: 'adverse-media' }, text: 'Adverse Media Record' },
      { value: { docCategory: 'entity', docType: 'articles-of-corporation' }, text: 'Articles of Corporation' },
      { value: { docCategory: 'entity', docType: 'articles-of-incorporation' }, text: 'Articles of Incorporation' },
      { value: { docCategory: 'entity', docType: 'articles-of-organization' }, text: 'Articles of Organization' },
      { value: { docCategory: 'entity', docType: 'audited-financials' }, text: 'Audited Financials' },
      { value: { docCategory: 'entity', docType: 'authorized-signers-list' }, text: 'Authorized Signers List' },
      { value: { docCategory: 'entity', docType: 'bank-statement' }, text: 'Bank Statement' },
      { value: { docCategory: 'entity', docType: 'board-resolutions' }, text: 'Board of Resolutions' },
      { value: { docCategory: 'entity', docType: 'by-laws' }, text: 'Executed Bylaws' },
      { value: { docCategory: 'entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
      { value: { docCategory: 'entity', docType: 'certificate-of-good-standing' }, text: 'Certificate of Good Standing' },
      { value: { docCategory: 'entity', docType: 'certificate-of-incorporation' }, text: 'Certificate of Incorporation' },
      { value: { docCategory: 'entity', docType: 'dba-name-certificate' }, text: 'DBA Name Certificate' },
      { value: { docCategory: 'entity', docType: 'employment-letter' }, text: 'Employment Letter' },
      { value: { docCategory: 'entity', docType: 'evidence-of-ein' }, text: 'Evidence of EIN' },
      { value: { docCategory: 'entity', docType: 'financial-documents' }, text: 'Financial Documents' },
      { value: { docCategory: 'entity', docType: 'identity-doc' }, text: 'Identity Document' },
      { value: { docCategory: 'entity', docType: 'idology-report' }, text: 'IDology Report' },
      { value: { docCategory: 'entity', docType: 'list-of-officers-and-directors' }, text: 'List of Officers & Directors' },
      { value: { docCategory: 'entity', docType: 'offering-agreement' }, text: 'Offering Memorandum' },
      { value: { docCategory: 'entity', docType: 'operating-agreement' }, text: 'Operating Agreement' },
      { value: { docCategory: 'entity', docType: 'organization-chart' }, text: 'Organizational Chart' },
      { value: { docCategory: 'entity', docType: 'proof-of-address' }, text: 'Proof of Address' },
      { value: { docCategory: 'entity', docType: 'selfie' }, text: 'Selfie' },
      { value: { docCategory: 'entity', docType: 'shareholders-list' }, text: 'Shareholders List' },
      { value: { docCategory: 'entity', docType: 'tax-document' }, text: 'Tax Document' },
      { value: { docCategory: 'entity', docType: 'other' }, text: 'Other' },
    ],
    individualConfidentialDocumentTypes: [
      { value: { docCategory: 'entity', docType: 'adverse-media' }, text: 'Adverse Media Record' },
      { value: { docCategory: 'entity', docType: 'bank-statement' }, text: 'Bank Statement' },
      { value: { docCategory: 'entity', docType: 'employment-letter' }, text: 'Employment Letter' },
      { value: { docCategory: 'entity', docType: 'evidence-of-ein' }, text: 'Evidence of EIN' },
      { value: { docCategory: 'entity', docType: 'financial-documents' }, text: 'Financial Documents' },
      { value: { docCategory: 'entity', docType: 'identity-doc' }, text: 'Identity Document' },
      { value: { docCategory: 'entity', docType: 'idology-report' }, text: 'IDology Report' },
      { value: { docCategory: 'entity', docType: 'tax-document' }, text: 'Tax Document' },
      { value: { docCategory: 'other', docType: 'other' }, text: 'Other' },
    ],
    documentTypeIndividual: [
      { value: { docCategory: 'identification', docType: 'passport' }, text: 'Passport' },
      { value: { docCategory: 'identification', docType: 'driving-licence' }, text: 'Driver License' },
      { value: { docCategory: 'identification', docType: 'national-identity-card' }, text: 'National ID card' },
      { value: { docCategory: 'address-proof', docType: 'other' }, text: 'Proof of Address' },
      { value: { docCategory: 'selfie', docType: 'selfie' }, text: 'Selfie' },
      { value: { docCategory: 'other', docType: 'other' }, text: 'Other' },
    ],
    documentTypeIndividualFull: [
      { value: { docCategory: 'identification', docType: 'adverse-media' }, text: 'Adverse Media Record' },
      { value: { docCategory: 'identification', docType: 'bank-statement' }, text: 'Bank Statement' },
      { value: { docCategory: 'identification', docType: 'driving-licence' }, text: 'Driver License' },
      { value: { docCategory: 'identification', docType: 'employment-letter' }, text: 'Employment Letter' },
      { value: { docCategory: 'identification', docType: 'evidence-of-ein' }, text: 'Evidence of EIN' },
      { value: { docCategory: 'identification', docType: 'financial-documents' }, text: 'Financial Documents' },
      { value: { docCategory: 'identification', docType: 'identity-doc' }, text: 'Identity Document' },
      { value: { docCategory: 'identification', docType: 'idology-report' }, text: 'IDology Report' },
      { value: { docCategory: 'identification', docType: 'national-identity-card' }, text: 'National ID card' },
      { value: { docCategory: 'identification', docType: 'passport' }, text: 'Passport' },
      { value: { docCategory: 'identification', docType: 'tax-document' }, text: 'Tax Document' },
      { value: { docCategory: 'identification', docType: 'other' }, text: 'Other' },
    ],
    documentsTypeEntity: {
      [SID.InvestorEntityTypes.REVOCABLE_TRUST]: [
        { value: { docCategory: 'entity', docType: 'document-or-certificate-of-trust' }, text: 'Certificate of Trust' },
        { value: { docCategory: 'entity', docType: 'list-of-trustees' }, text: 'List of Trustees' },
        { value: { docCategory: 'entity', docType: 'org-chart' }, text: 'Organizational Chart' },
      ],
      [SID.InvestorEntityTypes.IRREVOCABLE_TRUST]: [
        { value: { docCategory: 'entity', docType: 'document-or-certificate-of-trust' }, text: 'Certificate of Trust' },
        { value: { docCategory: 'entity', docType: 'list-of-trustees' }, text: 'List of Trustees' },
        { value: { docCategory: 'entity', docType: 'org-chart' }, text: 'Organizational Chart' },
      ],
      [SID.InvestorEntityTypes.LIMITED_GENERAL_PARTNERSHIP]: [
        { value: { docCategory: 'entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
        { value: { docCategory: 'entity', docType: 'partnership-agreement' }, text: 'Partnership Agreement' },
        { value: { docCategory: 'entity', docType: 'lp-list-and-authorised-signers-list' }, text: 'LP list and authorized signers list' },
        { value: { docCategory: 'entity', docType: 'org-chart' }, text: 'Organizational Chart' },
        { value: { docCategory: 'entity', docType: 'operational-address' }, text: 'Operational Address' },
      ],
      [SID.InvestorEntityTypes.LLC]: [
        { value: { docCategory: 'entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
        { value: { docCategory: 'entity', docType: 'articles-of-organization' }, text: 'Articles of Organization' },
        { value: { docCategory: 'entity', docType: 'operating-agreement' }, text: 'Operating Agreement' },
        { value: { docCategory: 'entity', docType: 'members-and-authorised-signers-list' }, text: 'Members\'s list and authorized signers list' },
        { value: { docCategory: 'entity', docType: 'org-chart' }, text: 'Organizational Chart' },
        { value: { docCategory: 'entity', docType: 'operational-address' }, text: 'Operational Address' },
      ],
      [SID.InvestorEntityTypes.CORPORATION]: [
        { value: { docCategory: 'entity', docType: 'by-laws' }, text: 'By-laws' },
        { value: { docCategory: 'entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
        { value: { docCategory: 'entity', docType: 'articles-of-corporation' }, text: 'Articles of Corporation' },
        { value: { docCategory: 'entity', docType: 'shareholders-list-and-authorised-signers-list' }, text: 'Shareholder\'s list and authorized signers list' },
        { value: { docCategory: 'entity', docType: 'org-chart' }, text: 'Organizational Chart' },
        { value: { docCategory: 'entity', docType: 'operational-address' }, text: 'Operational Address' },
      ],
      [SID.InvestorEntityTypes.FUND]: [
        { value: { docCategory: 'entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
        { value: { docCategory: 'entity', docType: 'private-placement-memorandum' }, text: 'Private Placement Memorandum' },
        { value: { docCategory: 'entity', docType: 'operating-agreement' }, text: 'Operating Agreement' },
        { value: { docCategory: 'entity', docType: 'list-of-managers' }, text: 'List of Managers' },
        { value: { docCategory: 'entity', docType: 'evidence-of-ein' }, text: 'Evidence of EIN' },
        { value: { docCategory: 'entity', docType: 'org-chart' }, text: 'Organizational Chart' },
        { value: { docCategory: 'entity', docType: 'operational-address' }, text: 'Operational Address' },
      ],
      [SID.InvestorEntityTypes.NON_PROFIT_FOUNDATION]: [
        { value: { docCategory: 'entity', docType: 'certificate-of-registration' }, text: 'Certificate of Registration' },
        { value: { docCategory: 'entity', docType: 'constitutional-document' }, text: 'Constitutional Document' },
        { value: { docCategory: 'entity', docType: 'foundation-charter' }, text: 'Foundation charter' },
        { value: { docCategory: 'entity', docType: 'non-profit-bylaws' }, text: 'Non-Profit Bylaws' },
        { value: { docCategory: 'entity', docType: 'members-and-authorised-signers-list' }, text: 'List of Members' },
        { value: { docCategory: 'entity', docType: 'authorized-signers-list' }, text: 'Authorized Signers List' },
        { value: { docCategory: 'entity', docType: 'operational-address' }, text: 'Operational Address' },
      ],
      [SID.InvestorEntityTypes.FOR_PROFIT_FOUNDATION]: [
        { value: { docCategory: 'entity', docType: 'certificate-of-registration' }, text: 'Certificate of Registration' },
        { value: { docCategory: 'entity', docType: 'constitutional-document' }, text: 'Constitutional Document' },
        { value: { docCategory: 'entity', docType: 'shareholders-list-25-percent-ubo-control' }, text: 'Shareholders List (25%+ UBO Control)' },
        { value: { docCategory: 'entity', docType: 'org-chart' }, text: 'Organizational Chart' },
        { value: { docCategory: 'entity', docType: 'list-of-officers-and-directors' }, text: 'List of Officers and Directors' },
        { value: { docCategory: 'entity', docType: 'authorized-signers-list' }, text: 'Authorized Signers List' },
        { value: { docCategory: 'entity', docType: 'operational-address' }, text: 'Operational Address' },
      ],
      other: [{ value: { docCategory: 'entity', docType: 'other' }, text: 'Other' }],
    },
    face: [
      { value: 'front', text: 'Front' },
      { value: 'back', text: 'Back' },
    ],
  },
};

export const docTypeTitleMap = {
  'adverse-media': 'Adverse Media Record',
  'articles-of-association': 'Articles of Association',
  'articles-of-corporation': 'Articles of Corporation',
  'articles-of-incorporation': 'Articles of Incorporation',
  'articles-of-organization': 'Articles of Organization',
  'audited-annual-report': 'Audited Annual Report',
  'audited-financials': 'Audited Financials',
  'authorized-signers-list': 'Authorized Signers List',
  'bank-statement': 'Bank Statement',
  'birth-certificate': 'Birth Certificate',
  'board-resolutions': 'Board of Resolutions',
  'by-laws': 'Executed Bylaws',
  'certificate-of-formation': 'Certificate of Formation',
  'certificate-of-good-standing': 'Certificate of Good Standing',
  'certificate-of-incorporation': 'Certificate of Incorporation',
  'certificate-of-naturalisation': 'Certificate of Naturalisation',
  'certificate-of-registration': 'Certificate of Registration',
  'constitutional-document': 'Constitutional Document',
  'corporate-charter': 'Corporate Charter',
  'document-or-certificate-of-trust': 'Certificate of Trust',
  'driving-licence': 'Driving Licence',
  'employment-letter': 'Employment Letter',
  'evidence-of-ein': 'Evidence of EIN',
  'financial-documents': 'Financial Documents',
  'form-adv': 'Form ADV',
  'foundation-charter': 'Foundation Charter',
  'home-office-letter': 'Home Office Letter',
  'identity-doc': 'Identity Document',
  'idology-report': 'IDology Report',
  'immigration-status-document': 'Immigration Status Document',
  'letter-of-certification': 'Letter of Certification',
  'letter-of-registration': 'Letter of Registration',
  'list-of-beneficiaries': 'List of Beneficiaries',
  'list-of-managers': 'List of Managers',
  'list-of-officers-and-directors': 'List of Officers and Directors',
  'list-of-trustees': 'List of Trustees',
  'lp-list-and-authorised-signers-list': 'Limited Partner List',
  'members-and-authorised-signers-list': 'List of Members',
  'memorandum-of-association': 'Memorandum of Association',
  'national-identity-card': 'National Identity Card',
  'non-profit-bylaws': 'Non-Profit Bylaws',
  'offering-agreement': 'Offering Memorandum',
  'operating-agreement': 'Operating Agreement',
  'operational-address': 'Operational Address',
  'org-chart': 'Organizational Chart',
  'organization-chart': 'Organizational Chart',
  'ownership-designation-letter': 'Ownership Designation Letter',
  'partnership-agreement': 'Partnership Agreement',
  'passport-card': 'Passport Card',
  'private-placement-memorandum': 'Private Placement Memorandum',
  'proof-of-address': 'Proof of Address',
  'register-of-partners': 'Register of Partners',
  'residence-permit': 'Residence Permit',
  'shareholders-list': 'Shareholders List',
  'shareholders-list-10-percent-ubo-control': 'Shareholders List (10%+ UBO Control)',
  'shareholders-list-20-percent-ubo-control': 'Shareholders List (20%+ UBO Control)',
  'shareholders-list-25-percent-ubo-control': 'Shareholders List (25%+ UBO Control)',
  'shareholders-list-and-authorised-signers-list': 'Shareholders & Authorized Signers List',
  'state-id': 'State ID',
  'tax-document': 'Tax Document',
  'tax-returns': 'Tax Returns',
  'tax-id': 'Tax ID',
  'trust-agreement': 'Trust Agreement',
  'uk-biometric-residence-permit': 'UK Biometric Residence Permit',
  'voter-id': 'Voter ID',
  'work-permit': 'Work Permit',
  'w-8-ben-e': 'W-8 BEN-E',
  'w-9': 'W-9',
  visa: 'Visa',
  selfie: 'Selfie',
  prospectus: 'Prospectus',
  passport: 'Passport',
  other: 'Other',
};
